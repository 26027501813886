import React, { Suspense, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { useAppSelector } from 'src/services/redux/app/store';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { UserModel } from './modules/auth/models/UserModel';
import AuthInit from './modules/auth/redux/AuthInit';
import fetchPermission from './permissions/fetch-permission';
import PermissionProvider from './permissions/permission-provider';
import { Routes } from './routing/Routes';

moment.tz.setDefault('UTC');

type Props = {
  basename: string;
};
const App: React.FC<Props> = ({ basename }) => {
  const { user }: UserModel | any = useAppSelector((state) => state.auth);

  return (
    <PermissionProvider fetchPermission={fetchPermission(user)}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
    </PermissionProvider>
  );
};

export { App };
