import { useQuery } from '@tanstack/react-query';
import { Octokit } from '@octokit/rest';

const octokit = new Octokit({
  auth: process.env.REACT_APP_GITHUB_TOKEN,
});

const owner = process.env.REACT_APP_GITHUB_OWNER;
const repo = process.env.REACT_APP_GITHUB_REPO;

export const fetchReleaseNotes = async () => {
  const { data } = await octokit.repos.listReleases({
    owner: owner!,
    repo: repo!,
  });

  return data.map((release) => ({
    title: release.tag_name,
    name: release.name,
    description: release.body,
    published_at: release.published_at,
    html_url: release.html_url,
    is_latest: release.id === data[0]?.id, // En son sürüm kontrolü
  }));
};

export const useReleaseNoteList = (onError?: (error: any) => void) => {
  return useQuery(['releaseNotes'], fetchReleaseNotes, {
    onError,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 5 minutes
  });
};
