import { StreamVideoClient, StreamVideo } from '@stream-io/video-react-sdk';
import { ReactNode, useEffect, useState } from 'react';
import { useAppSelector } from 'src/services/redux/app/store';

const StreamVideoProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAppSelector((state) => state.auth);
  const [client, setClient] = useState<StreamVideoClient>();

  useEffect(() => {
    if (!user || !user?.streamToken) return;

    try {
      const client = new StreamVideoClient({
        apiKey: 'shtqq4nrbfet',
        user: {
          id: String(user?.id),
          name: user?.name + ' ' + user?.surname || user?.id,
          image: user?.company?.company_logo,
        },
        token: user?.streamToken,
      });

      setClient(client);
    } catch (error) {
      console.error('Error creating StreamVideoClient:', error);
    }
  }, [user]);
  console.log('client', client);
  if (!client) return <>{children}</>;
  return (
    <StreamVideo client={client as any} language="tr">
      {children}
    </StreamVideo>
  );
};

export default StreamVideoProvider;
