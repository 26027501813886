import * as Sentry from '@sentry/react';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-photo-view/dist/react-photo-view.css';
import { Provider } from 'react-redux';
import 'react-tagsinput/react-tagsinput.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react';
// Axios
import axios from 'axios';
import 'bootstrap-icons/font/bootstrap-icons.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Chart, registerables } from 'chart.js';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 **/
import './_metronic/assets/sass/style.scss';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
// Apps
import { App } from './app/App';
import store, { persistor } from './services/redux/app/store';
import * as _redux from './setup';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

Chart.register(...registerables);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Yükleniyor...</div>}>
          <App basename={PUBLIC_URL} />
          <ToastContainer autoClose={3000} />
        </PersistGate>
      </Provider>
    </MetronicI18nProvider>
  </QueryClientProvider>,
);
