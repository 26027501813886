/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { toAbsoluteUrl } from 'src/_metronic/helpers';
import Restricted from 'src/app/permissions/restricted';
import { useAppDispatch, useAppSelector } from 'src/services/redux/app/store';
import { _changeCompany, companyChange } from 'src/services/redux/slices/auth/auth-slice';
import './styles/styles.css';

type Props = {
  show: boolean;
};

const HeaderUserMenu: FC<Props> = ({ show }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user, shallowEqual);
  const { payment_details } = useAppSelector((state) => state.paymentsDetail);
  const changeCompany = async (branch: any) => {
    dispatch(companyChange({ ...user, company_id: branch?.id, company: branch }));
  };

  return (
    <div className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px `} data-kt-menu="true">
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3 ">
          <div className="symbol symbol-50px me-5">
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="eduthon" />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-6  flex-wrap">
              {user?.name} {user?.surname}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{user?.authorization?.authorization_name}</span>
            </div>
            <span className="text-muted fs-7">{user?.company?.company_name}</span>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>
      <Restricted to="ROLE_ACCOUNT_SETTINGS">
        <div className="menu-item px-5 my-1 ">
          <Link to="/account/settings" className="menu-link px-5 d-flex align-items-center ">
            Hesap Ayarları
            {!payment_details && <div className="bullet bullet-dot bg-danger h-8px w-8px translate-middle mx-4  mt-3  "></div>}
          </Link>
        </div>
      </Restricted>

      <Restricted to="ROLE_BRANCHES">
        <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start" data-kt-menu-flip="bottom">
          <div className="menu-link px-5">
            <span className="menu-title">Şubeler</span>
            <span className="menu-arrow"></span>
          </div>

          <div className="menu-sub menu-sub-dropdown py-4">
            {user?.branches?.map((branch: any, idx: number) => (
              <div className="menu-item px-3 " key={idx}>
                <div className={clsx('menu-link px-5 ', { active: branch?.id === user?.company?.id })} onClick={() => changeCompany(branch)}>
                  {branch?.company_name}
                </div>
              </div>
            ))}
            <div className="menu-item px-3 ">
              <Link to="/branches" className={clsx('menu-link px-5')}>
                Tümünü Gör
              </Link>
            </div>
          </div>
        </div>
      </Restricted>

      <Restricted to="ROLE_INSTITUTION_PAYMENTS">
        <div className="menu-item px-5 my-1">
          <Link to="/profile/payments" className="menu-link px-5">
            Ödemeler
          </Link>
        </div>
      </Restricted>
      <Restricted to="ROLE_COMMISSION">
        <div className="menu-item px-5 my-1 ">
          <Link to="/commission" className="menu-link px-5 d-flex align-items-center ">
            Komisyon Bilgileri
          </Link>
        </div>
      </Restricted>

      <div className="menu-item px-5">
        <Link to="/logout" className="menu-link px-5">
          Çıkış
        </Link>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
